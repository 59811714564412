<template>
    <div class="quality">
        <div class="qua-1">
            <img src="https://khome2.tuzuu.com/vita/ahome_1.png">
            <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo">
            <div class="title">UitaUita｜唯你100%的质量保证</div>
        </div>

        <div class="qua-2">
            <div class="title">
                <p>「UitaUita」每日营养专家</p>
                <p>来自澳洲的健康管理品牌，通过国际公认的GMP、FDA、SGS认证。</p>
            </div>
            <div class="hr"></div>
            <div class="qua-item">
                <div class="title">原料检验的层层把关，通过SGS认证</div>
                <div class="flex-between" style="align-items: end">
                    <div class="left flex-column-center">
                        <van-image width="90px" height="90px" src="https://khome2.tuzuu.com/vita/ren_3.png?v=1"/>
                        <span>SGS认证</span>
                    </div>
                    <div class="right">
                        创建于1878年，是世界上领先的第三方从事产品质量控制和技术鉴定的跨国公司。
                        总部设在日内瓦,在世界各地设有1800多家分支机构和专业实验室和 59000多名专业技术人员，在142个国家开展产品质检、监控和保证活动。
                    </div>
                </div>
            </div>
            <div class="qua-item">
                <div class="title">生产过程的层层把关，通过国际公认的GMP、FDA认证</div>
                <div class="flex-between" style="align-items: end">
                    <div class="left flex-column-center">
                        <van-image width="90px" height="90px" src="https://khome2.tuzuu.com/vita/ren_1.png?v=1"/>
                        <span>GMP认证</span>
                    </div>
                    <div class="right">
                        GMP，中文含义是“良好生产规范”。世界卫生组织于1975年公布GMP标准，将GMP定义为指导食物、药品、医疗产品生产和质量管理的法规。
                    </div>
                </div>
            </div>
            <div class="qua-item">
                <div class="title">良好的生产规范认证</div>
                <div class="flex-between" style="align-items: end">
                    <div class="left flex-column-center">
                        <van-image width="90px" height="90px" src="https://khome2.tuzuu.com/vita/ren_2.png?v=1"/>
                        <span>FDA认证</span>
                    </div>
                    <div class="right">
                        FDA是一个由医生、律师、微生物学家、药理学家、化学家和统计学家等专业人士组成的致力于保护、促进和提高国民健康的政府卫生管制的监控机构。
                        通过FDA认证的食品、药品、化妆品和医疗器具对人体是确保安全而有效的。
                    </div>
                </div>
            </div>
            <div class="end-title">
                包装过程的层层把关，全程采用最高要求——药品级包装，安全可靠
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: ""
    }
</script>

<style scoped lang="less">
    .quality {
        letter-spacing: 0.5px;
        width: 100%;
        min-height: 100vh;
        background-color: #F2F2F2;
        font-size: 16px;
        padding-bottom: 30px;
        .qua-1 {
            position: relative;

            .title {
                position: absolute;
                font-size: 20px;
                font-weight: 700;
                color: white;
                top: -13px;
                bottom: 0;
                height: fit-content;
                margin: auto;
                width: 100%;
                text-align: center;
            }
        }

        .qua-2 {
            width: calc(100% - 20px);
            margin: auto;
            background-color: white;
            border-radius: 10px;
            margin-top: -60px;
            position: relative;
            z-index: 2;
            padding: 20px 15px 25px 15px;
            box-sizing: border-box;

            .title {
                font-size: 14px;
                color: #3F3F3F;
                line-height: 23px;
            }

            .hr {
                height: 1px;
                width: 100%;
                border-bottom: 1px solid #292C2F;
                margin-top: 15px;
                margin-bottom: 20px;
            }
        }

        .qua-item {
            margin-bottom: 30px;

            .title {
                color: #232323;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 10px;
            }

            .left {
                font-size: 12px;
                color: #232323;

                .van-image {
                   border:1px solid #e0e0e0;
                    border-radius: 10px;
                    margin-bottom: 10px;
                }
            }

            .right {
                font-size: 12px;
                line-height: 22px;
                color: #7e7e7e;
                margin-left: 10px;
            }
        }

        .end-title {
            font-size: 16px;
            color: #3f3f3f;
            line-height: 23px;
            font-weight: 700;
        }

        img {
            width: 100%;
            display: block;
        }
    }
</style>